import React from "react"

export default function BackgroundVideo({ src, setShow }) {
  return (
    <video
      className="absolute inset-0 w-full h-screen object-cover z-10"
      playsInline
      muted
      autoPlay
      onEnded={setShow}
    >
      <source src={src + ".webm"} type="video/webm" />
      <source
        src={src + ".mp4"}
        type="video/mp4; codecs=avc1.42E01E,mp4a.40.2"
      />
    </video>
  )
}
