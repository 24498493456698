import React, { Suspense } from "react"

import { Canvas } from "@react-three/fiber"
import { OrbitControls, PerspectiveCamera } from "@react-three/drei"
import { Model } from "./model"

export default function CanvasComponent({ show }) {
  const isBrowser = typeof window !== "undefined"

  return (
    isBrowser && (
      <Canvas color="transparent">
        <OrbitControls enableZoom={false} />
        {/* <OrbitControls enableZoom={false}  minPolarAngle={Math.PI/2}  maxPolarAngle={Math.PI/2}/> */}
        <PerspectiveCamera makeDefault fov={75} position={[0, 0, 9]} />
        <ambientLight intensity={1} />
        <directionalLight intensity={0} />
        <Suspense fallback={null}>
          <Model show={show} />
        </Suspense>
      </Canvas>
    )
  )
}
