import { useAnimations } from "@react-three/drei"
import React, { useEffect, useRef } from "react"

import { useLoader } from "@react-three/fiber"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"

export const Model = props => {
  const group = useRef()
  const gltf = useLoader(GLTFLoader, "/newbull.glb")
  const { scene, animations } = gltf

  const { actions } = useAnimations(animations, group)

  function handlePlay() {
    if (props.show) {
      actions["metarig.002Action"]?.play()
      setTimeout(() => {
        props.show && handleStop()
      }, 10000)
    }
  }

  function handleStop() {
    if (props.show) {
      actions["metarig.002Action"]?.stop()
      setTimeout(() => {
        props.show && handlePlay()
      }, 5000)
    }
  }

  useEffect(() => {
    if (group.current) {
      group.current.rotation.y = 0.2
    }

    handlePlay()

    return () => {
      handlePlay()
    }
  }, [props.show, handlePlay, handleStop])

  return scene && props.show ? (
    <mesh transparent position={[0, -4, 0]}>
      <group ref={group} {...props} dispose={null}>
        <primitive object={scene} />
      </group>
    </mesh>
  ) : null
}
