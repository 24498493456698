import * as React from "react"
import Header from "../../components/header"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import Canvas from "../../components/canvas"
import BackgroundVideo from "../../components/background-video"

const Rodeo = () => {
  const [tokenAmount, setTokenAmount] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [show, setShow] = React.useState(false)
  const { t } = useTranslation()

  React.useEffect(() => {

    const policyID = "ea2d23f1fa631b414252824c153f2d6ba833506477a929770a4dd9c2"
    const assetName = "4d414442554c"

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://cardano-mainnet.blockfrost.io/api/v0/addresses/addr1wy700n0cev4jr3j06remajyvetlks4kly349ahhnqkm9hwcz862yz`,
          {
            headers: {
              project_id: process.env.GATSBY_BLOCKFROST_PROJECT_ID,
            },
          }
        )
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText)
        }
        const data = await response.json()
        const token = data.amount.find(
          asset => asset.unit === `${policyID}${assetName}`
        )
        setTokenAmount(token ? token.quantity : "0")
      } catch (error) {
        setError(error.toString())
      }
    }

    fetchData()
  }, [])

  return (
    <div className="relative h-screen max-h-screen overflow-y-hidden bg-[#4A3732]">
      <div className="flex items-center justify-center h-full w-full">
        <Header location={{ pathname: "rodeo" }} t={t} />
        <BackgroundVideo setShow={() => setShow(true)} src="/madbull_statium" />
        <div className="w-full h-full z-20 mt-20 md:pl-40">
          <div className="relative w-full h-full ">
            <Canvas show={show} />
          </div>
        </div>
        {show && (
          <div className="fixed inset-0 w-full h-full z-40 flex flex-col justify-start pt-10 md:pt-20 lg:pt-28 items-center px-4 md:px-14 lg:px-28 pointer-events-none">
            <div className="text-white text-center w-full md:w-fit">
              <p className="pt-12 md:pt-0 text-red-700 text-5xl md:text-7xl font-digital animate-blink">
                {error ? (
                  <div>
                    {t("rodeoError")} {error}
                  </div>
                ) : tokenAmount ? (
                  <div>
                    {t("rodeoTokenAmount", { tokenAmount: tokenAmount })}
                  </div>
                ) : (
                  <div>{tokenAmount}</div>
                )}
              </p>
            </div>
          </div>
        )}
      </div>
      {show && (
        <footer className="fixed inset-x-0 bottom-0 z-50 flex justify-center pb-4">
          <a
            href="https://dripdropz.io/?ref=1WEywwMj"
            target="_blank"
            rel="noopener noreferrer"
            className="px-8 py-2 bg-blue-700 text-white text-xl font-bold rounded-sm transition-colors duration-300 hover:bg-blue-600"
          >
            {t("rodeoGrabNow")}
          </a>
        </footer>
      )}
    </div>
  )
}

export default Rodeo

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
